*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  line-height: 1.5715;
}
body{
  width: 100%;
  overflow-x: hidden;
  background-color: #f3f3f3;
}
