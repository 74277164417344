.loaderContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    width: 120px; /* control the size */
    aspect-ratio: 1;
    display: grid;
    -webkit-mask: conic-gradient(from 15deg, #0003,#000);
            mask: conic-gradient(from 15deg, #0003,#000);
    animation: load 1s steps(12) infinite;
  }
  .loader,
  .loader:before,
  .loader:after{
    background:
      radial-gradient(closest-side at 50% 12.5%,currentColor 90%,#0000 98%) 
      50% 0/20% 80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%,currentColor 90%,#0000 98%) 
      0 50%/80% 20% repeat-x;
  }
  .loader:before,
  .loader:after{
    content: "";
    grid-area: 1/1;
    transform: rotate(30deg);
  }
  .loader:after{
    transform: rotate(60deg);
  }
  @keyframes load {
    from {transform: rotate(0turn)}
    to   {transform: rotate(1turn)}
  }
