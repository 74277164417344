
.containerQr{
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(116, 114, 114, 0.318); */
    flex-direction: column;
    gap:1rem;
    overflow-x: hidden;
    padding: 0.5rem 0;
}

.containerQr .wrapperQr{
    width:560px;
    height: 800px;
    overflow-x: hidden;
    position: relative;

}

.qrImg{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    top:0;
    background-color: #CDF3E1;
    left: 0;
    bottom: 0;
    border:1.5rem solid #06C167;
    right: 0;
}

.qrImg .left{
    position: absolute;
    bottom: 0;
    /* left:-1.5rem; */
    padding:3rem 2rem 3rem 0.5rem;
    color: #06C167;
    background-color: #fff;
    border-radius: 0 55px 55px 0 ;
}

.left h1{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    position: relative;
}

.left h1::before{
    content: '';
    width: 4px;
    height: 90%;
    top:50%;
    border-radius: 30px;
    position: absolute;
    background: yellow;
    left: -14px;
    transform: translateY(-50%);
}

.wrapperQr .contentQr{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:2rem
}

.title {
    color: #06C167;
    margin-top: 5rem;
    font-size: 2rem;
}

.title h2{
    margin-bottom: 0;
}

.qrcode{
    margin-top: 1rem;
    width:60%
}

.qrcode img{
    width: 100%;
}

.QrHold{
    height: inherit;
}

.nextorder p:nth-child(1){
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 2.2rem;
    font-family: 'Poppins', sans-serif;
    margin-top: 0.5rem;
}
.nextorder p:nth-child(2){
    color:#000;
    text-align: center;
    font-weight: 500;
    margin-top: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.7rem;
}

.button{
    display: flex;
    gap: 2rem;
}

.name{
    text-align: center;
}
.button button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 45px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}
.button button:hover{
    color: white;
    background-color: #06C167;
}

.hide{
    display: none;
}