.appContainer{
    width: 100%;
    min-height: calc(100vh - 207.57px);
}

.appWrapper{
    width: inherit;
    height: inherit;
    display: flex;
}

.appLeft,.appRight{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 63vh;
}

.appRight{
    flex-direction: column;
    gap:3rem;
    align-items: flex-start;
    padding: 2rem;
}

.message h2{
    color:#02df75;
    text-align: justify;
}
.btnPart button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}

.btnPart button:hover{
    color: white;
    background-color: #06C167;
}
@media screen and (max-width:790px){
    .appWrapper{
        flex-direction: column;
    }
    .appLeft,.appRight{
        min-height: inherit;
    }
    .appRight{
        align-items: center;
    }
}

@media screen and (max-width:500px){
    .appContainer{
        min-height: calc(100vh - 234.57px);
    }
   
}