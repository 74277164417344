.container{
    width: 100%;
    min-height: 100vh;
}

.wrapper{
    width:100%;
    height: inherit;
    display: flex;
    padding: 2rem 0;
}

.left,.right{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.left{
    gap:1rem;
    height: 75vh;
    position: relative;
}
.leftWrapper{
    position: fixed;
}
.right{
    align-items: flex-end;
}
.message h1{
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    font-size: 2.5rem;
    color:#02df75;
    text-shadow: -3px 3px 3px rgba(0,0,0,0.6);
}

.loginForm{
    width: 85%;

}
form{
    width: 100%;
}

.formContainer{
    width: 100%;
    border-radius: 20px;
    padding: 3rem 2rem;
    display: flex;
    background-color: #fff;
    box-shadow: -1px 1px 18px 1px rgba(0,0,0,0.3);
    flex-direction: column;
    gap: 1.5rem;
}

.welcome{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.welcome p{
    font-size: 23px;
    font-weight: 500;
    text-align: center;
}

.welcome h1{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 34px;
    font-weight: 700;
    color: #06C167;
    text-align: center;
}
.address{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.address:focus ~label,.address:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.inputField{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inputField div{
    width: 100%;
    position: relative;
}
.inputContainer{
    width: 100%;
    padding: 0 1rem;
}

.inputField div label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color:#06C167;
    transition: all 250ms ease-in-out;

}

.input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.input:focus ~ label,.input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.hidePassword i{
    position: absolute ;
    top:-34px ;
    right: 20px;
    color: rgb(172, 171, 171);
    cursor: pointer;
}

.right select{
    width: 100%;
    outline: none;
    padding-left: 0.7rem;
    height: 57px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 13px;
    border: 2px solid #06C167;
    color: #06C167;
}


select option{
    font-weight: bold;
    font-size: 15px;
    color: rgb(64,62,61);
    padding: 0.5rem 1rem;
}

.signUpBtn button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}
.signUpBtn{
    display: flex;
    justify-content: center;
}
.signUpBtn button:hover{
    background-color: #06C167;
    color: white;
}

.haveAcc{
    display: flex;
    margin-top: -10px;
    justify-content: center;
}
.haveAcc span a{
    color: #06C167;
    font-weight: 700;
}
.registerPart{
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap: 2rem;
}
.agree{
    width: fit-content;
}

.question{
    display: flex;
    align-items: center;
    gap:0.5rem;
    margin: 0.6rem 0;
}

.question label{
    font-weight: 600;
    color:#06C167;
    cursor: pointer !important;
    pointer-events: all !important;
    margin-left: 0.5rem;
}
.question label a{
    color:#06C167;
    cursor: pointer;
}

.question label a:hover{
    text-decoration: underline;
}

.error{
    background-color: #CDF3E1;
    padding: 1rem;
    border-radius: 12px;
    text-align: center;
}
.error span{
    font-size: 0.9rem;
    font-weight: 700;
    color: rgb(131,131,145);
}

@media screen and (max-width:950px){
    .wrapper{
        flex-direction: column-reverse;
        gap:3rem;
    }
    .leftWrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .gifImg{
        width: 300px;
        height: 250px;
    }

    .gifImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .loginForm{
        margin: auto;
        margin-bottom: 3rem;
    }
    .inputContainer{
        padding: 0;
    }
}

@media screen and (max-width:450px){
    .loginForm{
        width: 95%;
    }
    .message h1{
        font-size: 2rem;
    }

    .welcome h1{
        font-size: 30px;
    }
    .welcome p{
        font-size: 20px;
    }
    
    .formContainer{
        padding: 2rem 1rem;
    }
} 