.container{
    width: 100%;
    /* height: 100vh; */
}

.wrapper{
    width: 100%;
    /* height:calc(100vh - 60px); */
}
.head h1 span{
    font-size: 1.5rem;
}
.head h1{
    color: #06C167;
    text-align: center;
    padding: 1rem;
    font-size: 2.5rem;
}

.content{
    padding: 0 7rem;
}
.pp ul{
    padding:1rem 2rem;
}
.pp{
    margin: 1rem 0;
}
.pp h1,.sa h1{
    color: #06C167;
}
.pp ul li{
    font-size: 1.1rem;
}
.iau{
    color: #06C167;
}
.iau h1,h2{
    margin-bottom: 1rem;
}
.iau p,.sa p,.pay p,.rp p,.gc p{
    font-size: 1.1rem;
    color: black;
}
.iau h1,.sa h1,.pay h1,.rp h1,.gc h1,.pp h1{
    color: #06C167;
}

.def,.gc,.pay,.rp{
    margin-top: 1.5rem;
}
.def p{
    font-size: 1.3rem;
}
.keyPoints{
    font-size: 1.3rem;
    margin-top: 1.5rem;
}
.keyPoints p a{
    color: #06C167;
    text-decoration: none; 
    font-size: 1.4rem;
    font-weight: bold;
}
.sa{
    margin-top: 1.5rem;
}

@media screen and (max-width:800px){
    .content{
        padding: 0 3rem;
    }
}
@media screen and (max-width:580px){
    .content{
        padding: 0 2rem;
    }
    .iau p,.sa p,.pay p,.rp p,.gc p{
        font-size: 1rem;
        color: black;
    }
    .iau h1,.sa h1,.pay h1,.rp h1,.gc h1,.pp h1{
        color: #06C167;
        font-size: 1.5rem;
    }
    .pp ul li{
        font-size: 1rem;
    }
}
@media screen and (max-width:500px){
  
    .content{
        padding: 0 1rem;
    }
    .iau p,.sa p,.pay p,.rp p,.gc p{
       text-align: justify;
    }
    .pp ul li{
        text-align: justify;
    }
}
@media screen and (max-width:400px){
    .iau h1,.sa h1,.pay h1,.rp h1,.gc h1,.pp h1{
        font-size: 1.2rem;
    }
    .iau h2{
        font-size: 1.1rem;
    }
}