.nav{
    width: 100%;
    height: 73px;
    background-color: #CDF3E1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 3rem;
    border-bottom: 4px solid #06C167;
}


.nav .leftControls{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.rightSec{
    flex: 1;
    display: flex;
    align-items: center;
    gap:1rem;
}

.rightSec h1{
  font-weight: 700;
    color: #06C167;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.6rem;
    font-weight: bold;
    position: relative;
    user-select: none;
    cursor: pointer;
}

.userName{
    font-weight: 700;
    cursor: pointer;
}
.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f3f3f3;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: -10px;
    border-radius: 12px;
    padding: 1rem 0;
  }

  .dropdownContent a {
    float: none;
    color: #06C167;
    padding: 6px 16px;
    font-weight: 700;
    font-size: 15px;
    text-decoration: none;
    display: block;
    border-radius: 12px;
    text-align: center;

  }

  .dropdownContent a:hover {
    border-radius: 12px;
    color: white;
    background-color: #06C167;
  }
  
  .dropdown:hover .dropdownContent {
    display: block;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }


  .dropdown .dropBtn {
    background: transparent;
    background-blend-mode: soft-light, normal;
    height: 38px;
    min-width: 100px;
    text-decoration: none;
    display: flex;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    color: #06C167;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 0px 15px;
    line-height: 100%;
    border: none;
    border-bottom: 4px solid #06C167;
    -webkit-user-select: none;
    user-select: none;
  }

  .parentSubMenu{
    position: relative;
  }

  .parentSubMenu:hover > .subMenu{
    display: block;
  }
  .subMenu{
    display: none;
    padding: 0.6rem;
    position: absolute;
    z-index: 11;
    left:-90%;
    top:-5px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 12px;
  }

  .subMenu li {
    color: #06C167;
    list-style-type: none;
  }

  .subMenu li a{
    color: #06C167;
    padding: 7px 16px;
    font-weight: 700;
    font-size: 15px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    border: none;
  }

  .subMenu li a:hover{
    border-radius: 12px;
    color: white;
    background-color: #06C167;
  }

@media screen and (max-width:450px){
    .userName{
        display: none;
    }
}

@media screen and (max-width:650px){
  .nav{
      padding: 0 1rem;
  }
}