.section{
    min-height: calc(100vh - 73px);
    margin: 0 3rem; 
    display: flex;
    margin-bottom: 3rem;
}

.rightPart{
    width: 100%;
    display: flex;
    justify-content: center;
}

.rightPart .wrapper{
    margin: 3rem 3rem 1rem 3rem;
    background-color: #fff;
    padding: 3rem 6rem;
    box-shadow: -3px 2px 13px 0px rgba(0,0,0,0.2);
    border-radius: 26px;
    display: flex;
    flex-direction: column;
}

.tabCont{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightPart .wrapper .buttons{
    background-color: #CDF3E1;
    padding: 1rem 3rem; 
    border-radius:26px ;
    display: flex;
    justify-content: center;
    box-shadow: -1px 6px 5px -4px rgba(0,0,0,0.5);
    gap:1rem;
}
.rightPart .wrapper .buttons button{
    position: relative;
    color:grey;
    border: none;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    background-color: #CDF3E1;
    cursor: pointer;
    font-size: 1rem;
    font-family:'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;

}

.rightPart .wrapper .tabs{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}


.rightPart .wrapper .buttons .btnBefore::before,.rightPart .wrapper .buttons .btn::before{
    content: "";
    position: absolute;
    background-color:#06C167;
    height: 4px;
    left:50%;   
    border-radius: 6px;
    bottom:-4px;
    width:50%;
    transform: translate(-50%);
    opacity: 0;
}

.btnBefore{
    font-weight: bold;
    color:black;
}

.rightPart .wrapper .buttons .btn:hover::before{
    opacity: 1;
}
.rightPart .wrapper .buttons .btn:hover{
    color: black;
}
.rightPart .wrapper .buttons .btnBefore::before{   
    opacity: 1;
}

@media screen and (max-width:1150px) {
    .rightPart .wrapper{
        padding: 3rem 2rem;
        margin: 3rem 2rem 1rem 2rem;
    }
    .rightPart .wrapper .buttons {
        gap:0.2rem;
    }
}
@media screen and (max-width:950px) {
    .section{
        margin: 0 2rem;
    }
    .rightPart .wrapper .buttons{
        padding: 1rem;
    }
    .rightPart .wrapper{
        margin:3rem 1rem 1rem 1rem;
    }
    
}

@media screen and (max-width:800px){
    .section{
        flex-direction: column;
        align-items: center;
    }
    .leftPart{
        grid-template-columns: repeat(2,minmax(0,1fr));
        padding: 1rem 1rem;
        grid-column-gap: 1.5rem;
    }
    .section .leftPart{
        width: 100%;
    }
    .rightPart .wrapper{
        width: 100%;
    }

}
@media screen and (max-width:650px){
    .section{
        margin: 0;
    }

    .rightPart .wrapper .buttons button{
        padding: 0.2rem 0.5rem;
    }
}

@media screen and (max-width:520px){
    .rightPart .wrapper .buttons{
        flex-direction: column;
        gap:0.7rem;
    }
}

@media screen and (max-width:425px){
    .rightPart .wrapper{
        padding:3rem 0.8rem ;
    }
}