.tab{
    width: 100%;
}

.formPart{
    width: 85%;
    margin: 0 auto;
}
.formPart .inputType{
    margin: 0.7rem 0;
}

.inputType{
    position: relative;
}

.exp p,.discountType p{
    color:#06C167
}

.inputType input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.inputType label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}
.inputType input:focus{
    border: 2px solid #06C167;
}
.inputType input:focus ~ label,.inputType input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
.checkout{
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}
.checkbtn{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}

.checkbtn:hover{
    color: white;
    background-color: #06C167;
}

.discountType p{
    margin-bottom: 0.5rem;
}

.expire{
    margin: 0.5rem 0;
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
}

.expire button{
    color: #06C167;
    flex: 1;
    background: transparent;
    padding: 0.5rem 0.5rem;
    font-size: 1.1rem;
    border: 2px solid #06C167;
    cursor:pointer;
    margin:0 0.3rem;
    border-radius: 15px;
    font-weight: 700;
    text-align: center;
}

.expire button:nth-child(1){
    margin-left: 0;
}
.expire button:nth-child(4){
    margin-right: 0;
}

.expire .active,.expire button:hover{
    background-color: #06C167;
    color: white;
}

.expire button:nth-child(1){
    border-left: 2px solid #06C167;
}

.inputType > div{
    position: relative;
}

.switchButton {
    background: rgba(255, 255, 255, 0.56);
    /* border-radius: 30px; */
    border-radius: 13px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    padding: 3px 6px 5px 3px;
    font-size: 18px;
    height: inherit;
    font-weight: 700;
    letter-spacing: 1px;
    color: #06C167;
    border: 2px solid #06C167;
    position: relative;
    /* padding-right: 120px; */
}
.switchButton:before {
    content:"Dollars";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}

.switchButtonCheckbox{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switchButtonCheckbox:checked + .switchButtonLabel:before {
    transform: translateX(100%);
    transition: transform 300ms linear;
}
.switchButtonCheckbox + .switchButtonLabel {
    position: relative;
    padding: 11px 0;
    display: block;
    width: 50%;
    user-select: none;
    pointer-events: none;
}
.switchButtonCheckbox + .switchButtonLabel:before {
    content: "";
    background: #CDF3E1;
    border: 1px solid #06C167;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 13px;
    /* border-radius: 30px; */
    transform: translateX(0);
    transition: transform 300ms;
}
.switchButtonCheckbox + .switchButtonLabel .switchButtonLabelSpan {
    position: relative;
}


.popupContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.popupWrapper{
    background-color: #fff;
    padding:3rem;
    cursor:default;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap:3rem;
}

.popupBtns{
    display: flex;
    justify-content: center;
}

.popupBtns button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    padding: 0px 15px;
    line-height: 100%;
    padding: 0.5rem 1rem;
}
.popupBtns a:hover{
    color: white;
    background-color: #06C167;
}
.closeDiv{
    position: relative;
}
.close{
    position: absolute;
    right: 23px;
    top:20px;
}

.close i{
    cursor: pointer;
    font-weight: 700;
}
@media screen and (max-width:970px){
    .formPart{
        width: 95%;
    }
}

@media screen and (max-width:650px){
    .formPart{
        width: 100%;
    }
}

@media screen and (max-width:500px){
    .expire button {
        font-size: 0.8rem;
        font-weight: bold;
    }
}
@media screen and (max-width:400px){
    .expire button {
        margin: 0;
        padding: 0.5rem 0.3rem;
    }
    .expire{
        gap:8px
    }
}