.footContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1rem;
    gap:0.5rem;
    background-color: white;
}

.privacyMsg{
    color: rgba(0,0,0,0.7);
    font-size: 0.8rem;
    font-weight: 700;
}
.privacyMsg a{
    font-size: 0.9rem;
    font-weight: 700;
    color: black;
}

.copyRight{
    font-size: 0.8rem;
    font-weight: 700;

}

@media screen and (max-width:500px){
    .copyRight{
        font-size: 0.6rem;
    }

    .privacyMsg{
        color: rgba(0,0,0,0.7);
        font-size: 0.6rem;
    }
    .privacyMsg a{
        font-size: 0.7rem;
    }
}