
.container{
    width: 100%;
    min-height: calc(100vh - 73px);
    margin-bottom: 3rem;
}
.wrapper{
    margin: 0 5rem;
}
.message,.title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    flex-direction: column;
    padding: 1rem;
    padding-top: 3rem;
    margin-bottom: 2rem; 
    gap: 1rem;
}

.title h1{
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    color: #06C167;
}
.title p{
    margin-bottom: 0;
    margin-top: 0;
    color: #06C167;
    font-size: 1.2rem;
    text-align: center;
}

.message{
    margin: 2rem 0;
}

.message .messageInside{
    display: flex;
    width: 60%;
    background-color: #fff;
    color: #06C167;
    gap:1rem;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2rem;
    border-radius: 20px;
    box-shadow: -1px 1px 18px 1px rgba(0,0,0,0.3);
}

.messageInside h1{
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    color: #06C167;
}

.message .messageInside .messageField{
    width: 100%;
}

.msgButton{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}
.msgButton button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}

.msgButton button:hover{
    color: white;
    background-color: #06C167;
}

.inputType{
    margin: 0.7rem 0;
    position: relative;
}

.inputType input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.inputType label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}

.inputType input:focus{
    border: 2px solid #06C167;
}
.inputType input:focus ~ label,.inputType input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
.textMsg textarea{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    resize: none;
    height: 65px;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}
.textMsg{
    position: relative;
}
.textMsg label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}

.textMsg textarea:focus{
    border: 2px solid #06C167;
}

.textMsg textarea:focus ~ label,.textMsg textarea:valid ~label{
    font-size: 0.8rem;
    transform: translate(1px,-30px);
}

.inputType > div,.textMsg > div{
    position: relative;
}

.popupContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupWrapper{
    background-color: #fff;
    padding:3rem;
    cursor:default;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap:3rem
}

.popupBtns{
    display: flex;
    justify-content: center;
}

.popupBtns a{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    padding: 0px 15px;
    line-height: 100%;
    padding: 0.5rem 1rem;
}
.popupBtns a:hover{
    color: white;
    background-color: #06C167;
}
.closeDiv{
    position: relative;
}
.close{
    position: absolute;
    right: 23px;
    top:20px;
}

.close i{
    cursor: pointer;
    font-weight: 700;
}
@media screen and (max-width:990px) {
    .wrapper{
        margin: 0 2rem;
    }
}

@media screen and (max-width:750px) {
    .wrapper{
        margin: 0;
    }
    .message .messageInside{
        display: flex;
        width: 80%;
    }
}
@media screen and (max-width:550px) {
 
    .message .messageInside{
        width: 95%;
        padding: 1rem;
    }
}