.container{
    width: 100%;
    min-height: calc(100vh - 244.57px);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper{
    width: 50%;
    height: fit-content;
    padding: 2rem;
    border-radius: 12px;
    background-color: #fff;
    margin: 3rem 3rem 0 3rem;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

form{
    width: 100%;
}

.resetPass{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.resetPass p{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.resetPass h1{
    font-size: 34px;
    color: #06C167;
    font-weight: 700;
    text-align: center;
}

.inputField{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}


.formContainer{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.inputField{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.inputResetField div{
    width: 100%;
    position: relative;
}
.inputReset{
    width: 100%;
    padding: 0 1rem;

}

.inputResetField div label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;

}
input[type="text"]{
    width: 100%;
    border:2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

input[type="text"]:focus ~ label,
input[type="text"]:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}


.forgotPassword span a{
    font-size: 14px;
    color: black;
}

.resetBtn{
    display: flex;
    justify-content: center;
}

.resetBtn button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    border:2px solid #06C167;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 150px;
    padding: 0px 15px;
    line-height: 100%;
}


.resetBtn button:hover{
    background-color:#06C167;
    color: #fff;
}

@media screen  and (max-width:930px){
    .wrapper{
        width: 75%;
    }   
}

@media screen  and (max-width:760px){
    .wrapper{
        width: 95%;
    }   
    .inputReset{
        padding: 0;
    }
}

@media screen  and (max-width:600px){
    .wrapper{
        width: 100%;
        padding: 0;
    }   

    .resetPass p{
        font-size: 16px;
        text-align: center;
    }
    .resetPass h1{
        width: 100%;
        text-align: center;
    }
}

@media screen  and (max-width:450px){
    .wrapper{
        margin: 3rem 0 0 0;
    }
    .formContainer{
        padding: 2rem 1rem;
    }
}
