.container{
    width: 100%;
    height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
    width: 80%;
    box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 30%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 3rem 2.5rem;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap:1.2rem;

}

.content h1{
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    color: #06C167;
}
.inputType{
    position: relative;
    width: 100%;
}

.inputType input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.inputType label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}

.inputType input:focus{
    border: 2px solid #06C167;
}

.inputType input:focus ~ label,.inputType input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.inputType > div{
    position: relative;
}

.content button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}

.content button:hover{
    color: white;
    background-color: #06C167;
}
.error{
    background-color: #f3d3cd;
    padding: 1rem;
    width: 100%;
    border-radius: 12px;
    text-align: center;
}
.error span{
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(131,131,145);
}

.popupContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupWrapper{
    position: relative;
    background-color: #fff;
    padding:3rem;
    cursor:default;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap:1rem
}

.popupBtns{
    display: flex;
    justify-content: center;
}
.popUpMsg h2{
    text-align: center;
    color: #06C167;
}

.popupBtns button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}
.popupBtns button:hover{
    background-color: #06C167;
    color:white;
}


.tile{
    width: fit-content;
    border: 2px solid #06C167;
    outline: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
    display: flex;
    align-items: center;
    gap:1rem;
}

.key{
    min-width: 185px;
    font-size: 1rem;
    font-weight: 700;
}
.value{
    min-width: 185px;
    font-size: 0.9rem;
    font-weight: 600;
}

.close{
    position: absolute;
    right: 26px;
    top:23px;
}

.close i{
    cursor: pointer;
    font-weight: 700;
}

@media screen and (max-width:650px){
    .content{
        width: 100%;
    }
}


@media screen and (max-width:600px){
    .tile{
        flex-direction: column;
        gap:0.5rem;
        width: 100%;
        align-items: flex-start;
    }
    .key{
        min-width: auto;
  
    }
    .value{
        min-width: auto;
    }
    .popupWrapper{
        width: 90%;
    }
}

@media screen and (max-width:500px){
    .content{
        padding: 2rem 1rem;
    }
}

@media screen and (max-width:400px){
    .popupWrapper{
        padding: 3rem 2rem;
    }
}