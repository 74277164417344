.container{
    width: 100%;
    min-height: calc(100vh - 244.57px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content h1{
    letter-spacing: 1px;
    color: #06C167;
    font-size: 3rem;
    margin: 2rem 0;
    text-align: center;
}

.parentRow{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:space-around;
    flex-direction: column;
}

.dataRow{
    width: 100%;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    margin-bottom: 1rem;
    border: 2px solid #06C167;
    border-radius: 15px;
}

.leftPart{
    display: flex;
    align-items: center;
    padding: 0 1rem;
    gap:1rem;
    width: 50%;
}

.image{
    width:50px;
    height: 50px;
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
}

.title p:nth-child(1){
    font-weight: bold;
    color: #06C167;
}

.title p:nth-child(2){
    font-weight: bold;
    color: gray;
}

.switchButton {
    background: rgba(255, 255, 255, 0.56);
    /* border-radius: 30px; */
    border-radius: 13px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    padding: 3px 6px 5px 3px;
    font-size: 18px;
    height: inherit;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(0,0,0);
    border: 2px solid #06C167;
    position: relative;
    /* padding-right: 120px; */
}
.switchButton:before {
    content:"Subscribe";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}

.switchButtonCheckbox{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switchButtonCheckbox:checked + .switchButtonLabel:before {
    transform: translateX(100%);
    transition: transform 300ms linear;
}
.switchButtonCheckbox + .switchButtonLabel {
    position: relative;
    padding: 10px 0;
    display: block;
    width: 50%;
    user-select: none;
    pointer-events: none;
}
.switchButtonCheckbox + .switchButtonLabel:before {
    content: "";
    background: rgb(255, 114, 114);
    border: 1px solid #06C167;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 13px;
    /* border-radius: 30px; */
    transform: translateX(0);
    transition: transform 300ms;
}

.switchButtonCheckbox + .switchButtonLabelBg:before{
    background: #70fab8;

}


.switchButtonCheckbox + .switchButtonLabel .switchButtonLabelSpan {
    position: relative;
}

.btnControls{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    display: inline-flex;
    flex-direction: column;
    gap:0.5rem;
}
.btnControls button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 45px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}


.btnControls button:hover{
    color: white;
    background-color: #06C167;
}

@media  screen and (max-width:730px) {
    .content{
        width: 90%;
      
    }
}
@media  screen and (max-width:550px) {
    .leftPart{
        width: 100%;
        justify-content: center;
    }
    .content{
        width: 100%;
    }
    .dataRow{
        gap:0.5rem;
        flex-direction: column;
    }
    .content h1{
        font-size: 2rem;
    }
    .parentRow{
      padding: 1rem;
    }
}



.switch input
{
  display: none;
}

.switch 
{
  display: inline-block;
  width: 60px;
  height: 30px; 
  margin: 4px;
  transform: translateY(50%);
  position: relative;
}

.slider
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #06C167, 0 0 4px #06C167;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.2s;
}

.slider:before
{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #06C167;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.2s;
}

input:checked + .slider:before
{
  transform: translateX(30px); 
  background-color: red;
}

input:checked + .slider
{
  box-shadow: 0 0 0 2px red, 0 0 8px red;
}

.sub{
    margin-top: -25px;
    margin-bottom: 1rem;
    color: #06C167;
    font-weight: bold;
}