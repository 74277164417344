.shareContainer{
    width: 100%;
    min-height: calc(100vh - 244.57px);
}

.shareWrapper{
    width: inherit;
    height: inherit;
    display: flex;
}

.shareLeft,.shareRight{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 63vh;
}

.shareRight{
    flex-direction: column;
    gap:3rem;
    padding: 2rem;
}

.message h1{
    font-size: 2.5rem;
    color:#02df75;
}
.icon{
    display: flex;
    gap: 1rem;
}

@media screen and (max-width:500px){
    .shareContainer{
        min-height: calc(100vh - 234.57px);
    }
    .icon{
        gap: 1rem;
    }
}

@media screen and (max-width:390px){
    .message h1{
        font-size: 2rem;
        color:#02df75;
    }
}