.nav{
    width: 100%;
    height: 73px;
    background-color: #CDF3E1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 3rem;
    border-bottom: 4px solid #06C167;
}


.nav .leftControls{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.rightSec{
    flex: 1;
    display: flex;
    align-items: center;
    gap:1rem;
}

.rightSec h1{
    color: #06C167;
    font-size: 1.6rem;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    font-weight: bold;
    position: relative;
    user-select: none;
    cursor: pointer;
}

@media screen and (max-width:650px){
  .nav{
      padding: 0 1rem;
  }
}