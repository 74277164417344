.container{
    width: 100%;
    min-height: calc(100vh - 207.57px);
    margin-bottom: 3rem;
}

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: inherit;
    min-height: calc(100vh - 207.57px);
    gap:2rem;
}

.content{
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 1rem;

}


.heading h1{
    font-weight: 700;
    margin-bottom: 0;
    color: #06C167;
    text-align: center;
    margin-top: 3rem;
}

.tableWrapper{
    padding: 1rem 3rem;
    width: 100%;
}


