.tab{
    width: 100%;
}

.formPart{
    width: 85%;
    margin: 0 auto;
}
.formPart .inputType{
    margin: 0.7rem 0;
}

.inputType{
    position: relative;
}
.inputType input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.inputType label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}
.inputType input:focus{
    border: 2px solid #06C167;
}
.inputType input:focus ~ label,.inputType input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
.checkout{
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}
.checkbtn{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 45px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}
.inputType > div{
    position: relative;
}
.checkbtn:hover{
    color: white;
    background-color: #06C167;
}

@media screen and (max-width:520px){
    .formPart{
        width: 100%;
    }

}