.container{
    width: 100%;
    height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content{
    width: 90%;
    border-radius: 20px;
    padding: 0.5rem 1.5rem 2.5rem 1.5rem;
    box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 30%);
}
.msg{
    width: 100%;
    margin: 1.5rem 0;
}

.msg h1{
    font-weight: 700;
    margin-top: 0;
    text-align: center;
    color: #06C167;
    margin-bottom: 0.3rem;
}

.msg p{
    text-align: center;
    color: #06C167;
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
}

.values{
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.values p{
    background-color: #06C167;
    font-size: 1rem;
    padding: 0.4rem 1rem;
    border-radius: 12px;
    color: white;
    font-weight: bold;
    margin-bottom: 0.3rem;
    cursor: pointer;
}

.expire{
    margin-top: 1rem;
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
}

.expire button{
    color: #06C167;
    flex: 1;
    background: transparent;
    padding: 0.5rem 0.5rem;
    font-size: 1.1rem;
    border: 2px solid #06C167;
    cursor:pointer;
    margin:0 0.3rem;
    border-radius: 15px;
    text-align: center;
}

.expire button:nth-child(1){
    margin-left: 0;
}
.expire button:nth-child(4){
    margin-right: 0;
}

.expire .active,.expire button:hover{
    background-color: #06C167;
    color: white;
}

.expire button:nth-child(1){
    border-left: 2px solid #06C167;
}


.inputType{
    margin: 0.7rem 0;
    position: relative;
}

.exp p{
    color:#06C167
}

.inputType input{
    width: 100%;
    border: 2px solid #06C167;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(131,131,145);
}

.inputType label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #06C167;
    transition: all 250ms ease-in-out;
}
.inputType input:focus{
    border: 2px solid #06C167;
}
.inputType input:focus ~ label,.inputType input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.generate{
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}
.generate div{
    display: flex;
    gap:2rem;
    justify-content: center;
}

.generate button,.previewPrint button{
    background:#CDF3E1;
    background-blend-mode: soft-light, normal;
    color: #06C167;
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    border:2px solid #06C167;
    transition: all 100ms linear 0s;
    border-radius: 12px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 45px;
    min-width: 130px;
    padding: 0px 15px;
    line-height: 100%;
}

.inputType > div{
    position: relative;
}

.generate button:hover,.previewPrint button:hover{
    color: white;
    background-color: #06C167;
}
.control{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap:0.5rem;
    cursor: pointer;
    color: #06C167;
    text-transform: uppercase;
    text-shadow: 2px 1px 0px rgba(0,0,0,0.3);
}
.up{
    font-size: 1.1rem;
}

@media screen and (max-width:950px){
    .content{
        width:95%
    }
}

@media screen and (max-width:580px){
    .content{
        width: 100%;
    }
    .expire button {
        font-size: 0.8rem;
        padding: 0.5rem 0.3rem; 
        font-weight: bold;
    }
}

@media screen and (max-width:470px){
    .expire button {
        margin: 0;
    }
    .content{
        padding: 0;
        box-shadow: none;
    }
    .expire{
        gap:8px;
    }
}
@media screen and (max-width:390px){
    .expire button {
        padding: 0.5rem 0.1rem;
    }
    .msg h1{
       font-size: 1.5rem;
    }
    
    .msg p{
        font-size: 1rem;
    }
    .generate{
        margin-top: 1.5rem;
    }
}

@media screen and (max-width:520px) {
    .generate div{
        gap:1rem;
        flex-direction: column;
    }
}